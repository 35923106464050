<template>
  <div>
    <div class="login-form">
      <div class="logo">
        <img src="../assets/logo.png" style="width: 180px">
      </div>
      <div class="form">
        <el-form :label-position="labelPosition" label-width="80px" :model="formData">
          <el-form-item label="账号">
            <el-input v-model="formData.user" @keydown.native.enter="submitForm"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="formData.pass" @keydown.native.enter="submitForm"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm"><i class="el-icon-key"></i> 立即登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {baseTitle} from '../configs/squareConfig'
import {checkLogin} from '../http/api'

export default {
  name: "Login",
  data() {
    return {
      labelPosition: 'right',
      formData: {
        user: '',
        pass: '',
      },
    }
  },
  methods: {
    submitForm() {
      if (this.formData.user === '' || this.formData.pass === '') {
        this.$message({
          message: '请输入账号、密码再登录',
          type: 'error',
        })
      } else {
        checkLogin(this.formData)
          .then(res => {
            if (res.data.code === 'OK') {
              this.$cookies.set('token', res.data.token, '7d')
              this.$router.push('/monitor/home')
            }
          })
          .catch(err => console.log(err))
      }
    },
  },
  activated() {
    document.title = '请登录 - ' + baseTitle
  },
}
</script>

<style scoped>
.login-form {
  text-align: center;
  width: 100%;
  margin-top: 5rem;
}

.login-form .logo {
  line-height: 2.5;
  font-size: 1.2rem;
  padding: 1rem 0;
}

.login-form .form {
  width: 400px;
  height: auto;
  margin: auto;
  text-align: left;
}
</style>
